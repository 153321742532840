<template>
    <div class="public-frame h-100" :class="theme">
        <div class="wrapper" :style="{backgroundColor:menu?'#eeeeee':'white'}">
            <div class="head-wrapper" :style="{height:menu?'120px':'70px'}">
                <div class="header">
                    <div class="flex-space-between h-100" :class="theme==='white'?'':'ctr'">
                        <div class="logo">
                            <img :src="logo" alt="" class="responsive-image">
                        </div>
                        <div class="d-flex" style="line-height: 22px;color: #ffffff">
                            <div class="mr-2 locale" @click="setLocale">{{ en ? "English" : "中文" }}</div>
                            <el-dropdown @command="handleCommand" style="" class="ml-2" v-if="user.token">
                                <div style="cursor: pointer;border:1px solid transparent;padding: 1px 5px;">
                                <span class="user-name">{{ user.name }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-top-left" command="logout">{{
                                            $t("TITLE.SIGN-OUT")
                                        }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <div style="cursor: pointer;border:1px solid transparent;padding: 1px 5px;" v-else >
                                <router-link class="sign-in-link" :to="{path:'/sign-in',query:{'_t':'/portal'}}">{{ $t('TITLE.SIGN-IN')}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <nav class="public-menu" v-if="menu">
                    <ul class="menu-wrapper ctr">
                        <li :class="$route.name==='主页'?'active':''"><router-link :to="{path:'portal'}"> {{$t("MENU.HOME")}}</router-link></li>
                        <li :class="$route.name==='直播'?'active':''"><router-link :to="{path:'live'}"> {{$t("MENU.LIVE")}} </router-link></li>
                        <li :class="$route.name==='回播'?'active':''"><router-link :to="{path:'playback'}"> {{$t("MENU.PLAYBACK")}} </router-link></li>
                        <!-- <li class=""><a href="#"> {{$t("MENU.CHANNEL")}} </a></li> -->
                        <li v-if="$organization.application" :class="$route.name==='申请'?'active':''"><router-link :to="{path:'application'}"> {{$t("MENU.APPLICATION")}} </router-link></li>
                        <li v-if="user && user.token && user.role_level > 2"> <a href="/admin">{{ $t("MENU.ADMIN")}}</a></li>
                        <li class="search-form">
                            <input type="text" :placeholder="$t('TITLE.SEARCH')">
                            <i class="el-icon-search"></i>
                            <span class="divider"></span>
                        </li>
                    </ul>
                </nav>
            </div>
            <slot name="default"></slot>
        </div>
        <div class="page-footer">
            <div class="ctr flex-in-middle ">
                <page-footer></page-footer>
            </div>
        </div>
    </div>
</template>

<script>
import CommonMixin from "@/common/mixins/common-mixin"
import "@/views/scss/public-frame-dark.scss"
import PageFooter from "@/components/page-footer";

export default {
    name: "public-frame-dark",
    components: {PageFooter},
    mixins: [CommonMixin],
    props:{
      menu:{
          type:Boolean,default(){
              return true;
          }
      },
    },
    data() {
        return {
            logo: "/assets/logo.png",
        }
    },
    computed:{
        theme() {
            return this.$organization.dark?"":"white";
        }
    },
    methods:{
        handleCommand(command) {
            if (command === 'logout') this.logout();
        },
        logout: function () { //退出登录
            this.$confirm(this.$t('MESSAGE.CONFIRM-QUIT'), this.$t('TITLE.INFO'), {
                confirmButtonText: this.$t('TITLE.OK'),
                cancelButtonText: this.$t('TITLE.CANCEL'),
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.clear();
                const return_url = this.$organization.signin_url;
                if (this.$organization.sso_out) {
                    window.location.href = this.$organization.sso_out + encodeURIComponent(return_url); // cas 跳转
                } else {
                    if (this.$organization.oauth_out) {
                        window.location.href = this.$organization.oauth_out + encodeURIComponent(return_url);
                    } else {
                        this.$router.replace({name:'sign-in'});
                    }
                }

            }).catch(() => {
            });
        },
        setLocale() {
            this.setLanguage(!this.en);
        }
    }
}
</script>

